import Vue from 'vue'
import App from './App.vue'
import moment from 'moment';
import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);
import 'popper.js';
import 'bootstrap';
import 'remixicon/fonts/remixicon.css'
import router from './router'
import store from './store'
import axios from "axios";
import DatePicke from 'vue2-datepicker'

DatePicke.locale('fr')
import { getCurrentUser, setCurrentUser } from '../src/utils';
import { apiUrl, firebaseConfig } from './constants/config';
import firebase from 'firebase/compat/app'
import "firebase/compat/messaging";
// initialiser firebase
import apiErrorsStatus from './enum/apiErrorsStatus'
let messaging =null
try{
    firebase.initializeApp(firebaseConfig);
    messaging = firebase.messaging();
}
catch(err){
    console.log("Ce navigateur ne prend pas en charge les API requises pour utiliser le SDK Firebase")
}
export default messaging ;


import { BootstrapVue, ModalPlugin } from 'bootstrap-vue';
Vue.use(BootstrapVue, ModalPlugin);

import VueSweetalert2 from 'vue-sweetalert2'
Vue.use(VueSweetalert2)
import Swal from 'sweetalert2/src/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css';
import NiceNumericInput from 'nice-numeric-input'
Vue.use(NiceNumericInput)
    // Colxx Component Add
import Colxx from './components/common/Colxx'
Vue.component('b-colxx', Colxx);
// Notification Component
import Notifications from './components/common/Notification'
Vue.use(Notifications);

import VueBreadcrumbs from 'vue-breadcrumbs'
Vue.use(VueBreadcrumbs);

Vue.use(require('vue-shortkey'));


import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

import VueLottiePlayer from "vue-lottie-player";
Vue.use(VueLottiePlayer);

import jspdf from "jspdf";
Vue.use(jspdf);

import Acl from './acl/Acl';
Vue.use(Acl);

import html2canvas from "jspdf";
Vue.use(html2canvas);

import i18n from './locales/index';
localStorage.setItem('lang', i18n.locale);
import FlagIcon from 'vue-flag-icon';
Vue.use(FlagIcon);

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});
Vue.filter('formatHeure', function(value) {
    if (value) {
        return moment(String(value)).format('HH:mm')
    }
});

Vue.filter('formatDateTime', function(value) {
    let format = 'DD/MM/YYYY HH:mm';
    if (value) {
        return moment(String(value)).format(format)
    }
});
Vue.filter('upperCased', function(data) {
    let upper = [];
    data.split(" ").forEach((word) => {
        upper.push(word.toUpperCase());
    });
    return upper.join(" ");
});

Vue.filter('truncate', function(data, n) {
    if (data) {
        return data.length > n ? data.substr(0, n - 1) + '...' : data
    }
});
Vue.filter('firstLetter', function(data) {
    if (data) {
        return data.length > 0 ? data.substr(0, 1) : data
    }
});



axios.defaults.baseURL = `${apiUrl}`;
// axios.defaults.withCredentials = true;

axios.interceptors.response.use(function(response) {
    // END OF SESSION.
        return response
    },
    function(error) {
        let data = JSON.parse(localStorage.getItem('user'));
        if (data) {
            if (error.response?.status === 401) {
                setCurrentUser(null);
                router.push({ name: 'login' });
            }
            if (!error.response?.status){
                return Promise.reject(error)
            }
            /**
             * @todo si code 300 ++ , on va recevoir un json avec code d erreur et message , a mettre en place pour get marge ex: 301 pas de code promo connu pour determiné la marge on vous propose d utilisé la mager code 00 (Ok sitout)
             */
            if (![200,201,401, 400,406].includes(error.response?.status)) {
                    const swalWithBootstrapButtons = Swal.mixin ({
                        customClass: {
                            cancelButton: "btn  cancel-changes btn-outline-danger btn-block",
                        },
                        buttonsStyling: true,
                    });
                    swalWithBootstrapButtons
                    .fire ({
                        html :'<div class="modal-md"><div class="dummy-positioning d-flex "><i class="ri-error-warning-line"></i></div><div class=" justify-content-center modal--changes"><h1>Oops...</h1> <strong>'+error.response?.status+'</strong> <p><br>'+
                        apiErrorsStatus.getValue(
                            error.response.status
                          )+'</p>  </div></div>',
                        showCancelButton: true,
                        showConfirmButton : false,
                        cancelButtonText: 'OK',
                    })
                    .then ((result) => {
                    })
                    .catch((errr) => {

                      console.log(errr)
                    });
            }
            if ([406].includes(error.response?.status)) {
                console.log('i18n   => ', i18n.t('wording.notgestion'))
                const swalWithBootstrapButtons = Swal.mixin ({
                    customClass: {
                        cancelButton: "btn  cancel-changes btn-outline-danger btn-block",
                    },
                    buttonsStyling: true,
                });

                swalWithBootstrapButtons
                    .fire ({
                        html :'<div class="modal-md">' +
                            '   <div class=" justify-content-center modal--changes-"> ' +
                            '       <p class="text-justify">'+i18n.t('wording.error406global', {error:i18n.t("406."+error.response.data.error), code_action_client:i18n.t("406."+error.response.data.code_action_client)})+'</p> ' +
                            '  </div>' +
                            '</div>',
                        showCancelButton: true,
                        showConfirmButton : false,
                        cancelButtonText: 'OK',
                    })
                    .then ((result) => {
                    })
                    .catch((errr) => {

                        console.log(errr)
                    });
            }
        }
        return Promise.reject(error)
    })
    // add axios headers
    // if (getCurrentUser() || JSON.parse(window.localStorage.getItem("user"))) {
    //     axios.defaults.headers.common['Authorization'] = "Bearer " + JSON.parse(window.localStorage.getItem("user")).token;
    // }
Vue.config.productionTip = false
new Vue({
    i18n,
    router,
    store,
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                if (to.name) {
                    document.title = 'EASYMAT | ' + this.$t('title.' + to.name);
                }
                if (getCurrentUser() || JSON.parse(window.localStorage.getItem("user"))) {
                    axios.defaults.headers.common['Authorization'] = "Bearer " + JSON.parse(window.localStorage.getItem("user")).token;
                }
            }
        },
    },
    render: h => h(App),

}).$mount('#app')
