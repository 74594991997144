import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);
const files = require.context('./', false, /\.json$/);
const messages = {};

files.keys().forEach((key) => {
    const lang = key.match(/([a-z]+)\.json$/i)[1]; // Extrait le nom de la langue
    messages[lang] = files(key);
});

export default new VueI18n({
    locale: 'fr',
    messages
})